<template>
<div class="m-pg-sub">
  <page-cover code="3" layer="rgba(0,0,0,.3)">
    <h2 class="m-page-title">{{$t('page.detail.name')}}</h2>
    <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/search' }">{{$t('page.search.name')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('page.detail.name')}}</el-breadcrumb-item>
    </el-breadcrumb>
  </page-cover>
  <div class="m-pg-sub_main m-w1200">
    <tab-nav
      :data="tabNavData"
      :active.sync="tabNavActiveUrl">
    </tab-nav>
    <div class="child-main">
      <router-view></router-view>
    </div>
  </div>
</div>
</template>

<script>
import TabNav from '@/components/tab-nav'
export default {
  components: {
    TabNav,
  },
  data() {
    let sampleId = this.$route.query.sampleId
    return {
      tabNavData: [
        {name: this.$t('page.detail.m_tab.base'), url: `/search/result-detail/sample?sampleId=${sampleId}`},
        {name: this.$t('page.detail.m_tab.micronutrient'), url: `/search/result-detail/micronutrient?sampleId=${sampleId}`},
        {name: this.$t('page.detail.m_tab.principalElement'), url: `/search/result-detail/principal-element?sampleId=${sampleId}`},
        {name: this.$t('page.detail.m_tab.otopes'), url: `/search/result-detail/otopes?sampleId=${sampleId}`},
        {name: this.$t('page.detail.m_tab.ICPMS'), url: `/search/result-detail/ICPMS?sampleId=${sampleId}`}
      ],
      tabNavActiveUrl: ''
    }
  },
  methods: {
    tabNavActiveUrlInit() {
      // let path = this.$route.path
      // // 优先当前路由（如：直接带过来详情url）
      // let tab = this.tabNavData.find(item => item.url.split('?')[0] === path)
      // if (tab) {
      //   this.tabNavActiveUrl = tab.url
      //   return
      // }
    }
  },
  created() {
    this.tabNavActiveUrlInit()
  },
  mounted() {
    this.tabNavActiveUrlInit()
  }
}
</script>

<style lang="less" scoped>
.m-pg-sub_main {
  margin: 20px auto;
}
</style>
